/**
 * @generated SignedSource<<54b2de1b6b398032f4824c42e9647fee>>
 * @relayHash a2c71f32a3fa99c1c9d1bddef5dfcb86
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/497.0.0-2025-02-10T13:07:22.933Z/storefrontFollowDealerMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FavoriteSellerInput = {
  clientMutationId?: string | null;
  favoriteId?: string | null;
  sellerPk: string;
  userId: string;
};
export type storefrontFollowDealerMutation$variables = {
  input: FavoriteSellerInput;
  userId: string;
};
export type storefrontFollowDealerMutation$data = {
  readonly favoriteSeller: {
    readonly seller: {
      readonly favoritesByUser: ReadonlyArray<{
        readonly serviceId: string | null;
      } | null> | null;
    } | null;
  } | null;
};
export type storefrontFollowDealerMutation = {
  response: storefrontFollowDealerMutation$data;
  variables: storefrontFollowDealerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "userIds",
    "variableName": "userId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "storefrontFollowDealerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FavoriteSellerPayload",
        "kind": "LinkedField",
        "name": "favoriteSeller",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Seller",
            "kind": "LinkedField",
            "name": "seller",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Favorite",
                "kind": "LinkedField",
                "name": "favoritesByUser",
                "plural": true,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "storefrontFollowDealerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FavoriteSellerPayload",
        "kind": "LinkedField",
        "name": "favoriteSeller",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Seller",
            "kind": "LinkedField",
            "name": "seller",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Favorite",
                "kind": "LinkedField",
                "name": "favoritesByUser",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/497.0.0-2025-02-10T13:07:22.933Z/storefrontFollowDealerMutation",
    "metadata": {},
    "name": "storefrontFollowDealerMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "912a25bb1043faea3e9f3a5e5b7f9791";

export default node;
